import { IHumidorBase, IHumidorLite, IHumidorsState } from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as humidorsActions from '@store/actions/humidors';
import * as userActions from '@store/actions/user';

export const NAMESPACE = 'humidors';

export const adapter: EntityAdapter<
  IHumidorBase | IHumidorLite | Partial<IHumidorBase>
> = createEntityAdapter<IHumidorLite>({
  selectId: (entity: IHumidorLite) => entity.Id,
});

export const initialState: IHumidorsState = adapter.getInitialState({
  isLoading: false,
});

export const HumidorsReducer = createReducer(
  initialState,
  on(
    humidorsActions.HumidorsRequest,
    humidorsActions.HumidorCreateRequest,
    humidorsActions.HumidorUpdateRequest,
    humidorsActions.HumidorDeleteRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(humidorsActions.HumidorsReorderRequest, (state) => ({
    ...state,
    ...adapter.removeAll(state),
    isLoading: true,
  })),
  on(
    humidorsActions.HumidorsSuccess,
    humidorsActions.HumidorsReorderSuccess,
    (state, { humidors }) => ({
      ...state,
      ...adapter.setAll(humidors, state),
      isLoading: false,
    })
  ),
  on(
    humidorsActions.HumidorCreateSuccess,
    humidorsActions.HumidorUpdateSuccess,
    humidorsActions.HumidorSocketOfflineUpdate,
    (state, { humidor }) => ({
      ...state,
      ...adapter.upsertOne(humidor, state),
      isLoading: false,
    })
  ),
  on(humidorsActions.HumidorDeleteSuccess, (state, { humidorId }) => ({
    ...state,
    ...adapter.removeOne(humidorId, state),
    isLoading: false,
  })),
  on(humidorsActions.ErrorAction, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default HumidorsReducer;
