import { IHumidorBase, IHumidorLite } from '@models';
import { createAction, props } from '@ngrx/store';

export const HumidorsRequest = createAction('HUMIDORS_REQUEST');
export const HumidorsSuccess = createAction(
  'HUMIDORS_SUCCESS',
  props<{ humidors: IHumidorLite[] }>()
);

export const HumidorsReorderRequest = createAction(
  'HUMIDOR_REORDER_REQUEST',
  props<{ ids: string[] }>()
);
export const HumidorsReorderSuccess = createAction(
  'HUMIDOR_REORDER_SUCCESS',
  props<{ humidors: IHumidorLite[] }>()
);
export const HumidorCreateRequest = createAction(
  'HUMIDOR_CREATE_REQUEST',
  props<{ name: string }>()
);
export const HumidorCreateSuccess = createAction(
  'HUMIDOR_CREATE_SUCCESS',
  props<{ humidor: IHumidorLite }>()
);
export const HumidorUpdateRequest = createAction(
  'HUMIDOR_UPDATE_REQUEST',
  props<{
    humidorId: string;
    humidor: Partial<IHumidorLite>;
    changeOwner?: boolean;
  }>()
);
export const HumidorSocketOfflineUpdate = createAction(
  'HUMIDOR_SOCKET_OFFLINE_UPDATE',
  props<{ humidor: Partial<IHumidorLite> }>()
);
export const HumidorUpdateSuccess = createAction(
  'HUMIDOR_UPDATE_SUCCESS',
  props<{ humidor: IHumidorBase | IHumidorLite }>()
);
export const HumidorDeleteRequest = createAction(
  'HUMIDOR_DELETE_REQUEST',
  props<{ humidorId: string }>()
);
export const HumidorDeleteSuccess = createAction(
  'HUMIDOR_DELETE_SUCCESS',
  props<{ humidorId: string }>()
);
export const ErrorAction = createAction('HUMIDORS_ERROR');

export type ActionsUnion =
  | typeof HumidorsRequest
  | typeof HumidorsSuccess
  | typeof HumidorCreateRequest
  | typeof HumidorCreateSuccess
  | typeof HumidorUpdateRequest
  | typeof HumidorUpdateSuccess
  | typeof HumidorDeleteRequest
  | typeof HumidorDeleteSuccess
  | typeof ErrorAction;
